import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import Calendar from 'react-calendar';
import './DateAndTime.scss';
import AccordionComponentEvening from "../../components/AccordionComponent/AccordionComponentEvening";
import AccordionComponentMorning from "../../components/AccordionComponent/AccordionComponentMorning";
import AccordionComponentDay from "../../components/AccordionComponent/AccordionComponentDay";
import {useQuery} from "@apollo/client";
import {BOOKING_DATES, BOOKING_TIMES, COMPANY_BRANCH_INFO} from "../../apollo/Queries";
import ConvertDateForRequest from "../../utils/workingWithDateTime/ConvertDateFoRequest";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {apolloClientPos, apolloClientPosBranch} from "../../apollo/ApolloClients";
import {useDispatch, useSelector} from "react-redux";
import {setDate} from "../../store/dateReducer";
import Spinner from "../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";
import languageConvert from "../../utils/languageConvert";
import {setBookingValidate} from "../../store/bookingValidateReducer";
import convertDateByTimezone from "../../utils/workingWithDateTime/convertDateByTimezone";
import DateAndTimeWrapperComponent from "../../UI/DateAndTimeWrapperComponent/DateAndTimeWrapperComponent";
import {day, morning, night, splitTimeSpans} from "../../utils/workingWithDateTime/splitTimeSpans";
import {setBranch} from "../../store/branchReducer";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";

const DateAndTime = () => {

    const {t: translate, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const {companyId, branchId} = useParams();
    const currentService = useSelector(state => state.serviceReducer.serviceId);
    const currentEmployee = useSelector(state => state.employeeReducer.employeeId)
    const date = useSelector(state => state.dateReducer.currentDate);
    const dateIsEntered = useSelector(state => state.dateReducer.dateIsEntered);
    const dateStageInfo = useSelector(state => state.dateReducer);
    const dispatch = useDispatch();
    const timeZone = useSelector(state => state.branchReducer.timeZone);
    const currentTimeByTimeZone = convertDateByTimezone(timeZone);
    const currentBranch = useSelector(state => state.branchReducer.branchName);
    const [startDateByEmployee, setStartDateByEmployee] = useState(convertDateByTimezone(timeZone));
    const dateForRequest = date ? new Date(date) : currentEmployee ? new Date(startDateByEmployee) : convertDateByTimezone(timeZone);
    const [, setLoading] = useState(true);
    const [morningIsOpen, setMorningIsOpen] = useState(false);
    const [dayIsOpen, setDayIsOpen] = useState(false);
    const currentWindowWidth = window.innerWidth;
    const targetRef = useRef(null);
    const isBookingValid = useSelector(state => state.bookingValidateReducer.validate);
    const navigate = useNavigate();

    const {data: bookingDatesData} = useQuery(
        BOOKING_DATES(branchId, currentEmployee, currentService, true),
        {
            client: apolloClientPosBranch, onCompleted: (data) => {
                setStartDateByEmployee(data?.bookingDates[0]?.start)
            }
        });

    useQuery(COMPANY_BRANCH_INFO(branchId), {
        client: apolloClientPos,
        onCompleted: (data) => {
            if (!currentBranch) {
                dispatch(setBranch({
                    branchName: data.branch.name,
                    branchAddress: data.branch.address,
                    branchCity: data.branch.city.name,
                    timeZone: data.branch.timeZone
                }));
            }
            setLoading(false);
        }, onError: (error) => {
            console.log(error)
            setLoading(false)
        }
    });

    const {data: bookingTimesData} = useQuery(
        BOOKING_TIMES(ConvertDateForRequest(dateForRequest), branchId, currentEmployee, currentService, true), {
            client: apolloClientPosBranch, onCompleted: () => {
                setLoading(false)
            }
        }
    );

    const [bookingTimes, setBookingTimes] = useState([]);


    useEffect(() => {
        setBookingTimes(bookingTimesData?.bookingTimes);
        if (currentWindowWidth < 520) {
            if (targetRef.current) {
                targetRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }
        if (!isBookingValid) {
            dispatch(setBookingValidate({validate: true}))
        }
    }, [bookingTimesData?.bookingTimes, currentWindowWidth, dateIsEntered, dispatch, isBookingValid]);


    if (bookingTimes) {
        splitTimeSpans(bookingTimes, timeZone)
    }

    const isDisabled = (date) => {
        for (let i = 0; i < bookingDatesData?.bookingDates.length; i++) {
            const {start, end} = bookingDatesData?.bookingDates[i];
            if (date >= new Date(start).setHours(0, 0, 0) && date <= new Date(end).setHours(0, 0, 0)) {
                return false;
            }
        }
        return true;
    }

    const changeDateHandler = (pickedDate) => {
        dispatch(setDate({currentDate: pickedDate, dateIsEntered: false, pickedTime : null}));
        apolloClientPosBranch.query({
            query: BOOKING_TIMES(ConvertDateForRequest(new Date(pickedDate)), branchId, currentEmployee, currentService, true)
        }).then(({data}) => {
            setBookingTimes(data.bookingTimes);
        }).catch(error => {
            console.log(error)
        })
    }

    const pickDateHandler = () => {
        dispatch(setDate({currentDate: dateStageInfo.pickedTime, dateIsEntered: true}));
        navigate(`/company/${companyId}/branch-menu-list/${branchId}`);
    }

    const navigationLabel = ({date}) => {
        const locale = languageConvert(currentLanguage);
        const month = date.toLocaleString(locale, {month: 'long'});
        const year = date.getFullYear();
        return <div className="d-flex flex-column">
            <span className="fs-5 main_text_color fw-bold mb-2 text-capitalize">{month}</span>
            <span className="data-year main_text_color fs-18">{year}</span>
        </div>;
    };

    const today = convertDateByTimezone(timeZone);
    const minDate = new Date(today.getFullYear(), today.getMonth(), 1);


    return (
        <>
            <NavigationComponent
                navLink={`/company/${companyId}/branch-menu-list/${branchId}`}
                linkText={translate('backToMenu')}
            />
            <div className={'d-none d-sm-block container'}>
                <div className={'px-4 py-3 rounded bg-white mb-4 col-lg-8 mx-auto'}>
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <h5 className="fw-bold mb-1 col-7 col-md-8 main_text_color branch-name">{currentBranch}</h5>
                        {dateStageInfo.pickedTime ?
                            <button onClick={pickDateHandler} className={'btn btn-primary m-auto px-4 d-block fw-bold fs-7  rounded'}>
                                {translate('branchMenuPage.setDateTime')}
                            </button>
                            :
                            <button disabled className={'btn text-white btn-secondary m-auto px-4 d-block fw-bold fs-7  rounded'}>
                                {translate('branchMenuPage.setDateTime')}
                            </button>
                        }
                    </div>
                </div>
            </div>
            {(bookingDatesData && bookingTimesData) ?
                <>
                    <ContentWrapper>
                        <Calendar
                            onChange={changeDateHandler}
                            tileDisabled={({date, view}) => (
                                view === 'month' && isDisabled(date))}
                            value={dateForRequest}
                            navigationLabel={navigationLabel}
                            minDate={minDate}
                            locale={languageConvert(currentLanguage)}
                        />
                        <div className="calendar-info icon_to_block mt-3">
                            <p className="mb-2 d-flex align-items-center text-primary fs-6">
                                <i className="bi bi-circle-fill me-2 fs-8"></i>
                                {translate('dateAndTimePage.availableForReservation')}
                            </p>
                            <p className="d-flex mb-0 align-items-center text-secondary fs-6">
                                <i className="bi bi-circle-fill me-2 fs-8"></i>
                                {translate('dateAndTimePage.notAvailableForReservation')}
                            </p>
                        </div>
                    </ContentWrapper>
                    <div className="accordion border-0" ref={targetRef} id="accordion-times">
                        <DateAndTimeWrapperComponent>
                            <AccordionComponentMorning
                                morningIntervals={morning}
                                accordionPosition={'morning'}
                                currentDate={dateForRequest}
                                morningIsOpen={setMorningIsOpen}
                                convertedDateByTimeZone={currentTimeByTimeZone}/>
                        </DateAndTimeWrapperComponent>

                        <DateAndTimeWrapperComponent>
                            <AccordionComponentDay
                                dayIntervals={day}
                                accordionPosition={'day'}
                                currentDate={dateForRequest}
                                dayIsOpen={setDayIsOpen}
                                morningIsOpen={morningIsOpen}
                                convertedDateByTimeZone={currentTimeByTimeZone}/>
                        </DateAndTimeWrapperComponent>

                        <DateAndTimeWrapperComponent>
                            <AccordionComponentEvening
                                nightIntervals={night}
                                accordionPosition={'night'}
                                currentDate={dateForRequest}
                                dayIsOpen={dayIsOpen}
                                morningIsOpen={morningIsOpen}
                                convertedDateByTimeZone={currentTimeByTimeZone}/>
                        </DateAndTimeWrapperComponent>
                    </div>

                    {dateStageInfo.pickedTime ?
                        <button onClick={pickDateHandler} className={'btn mt-3 btn-primary m-auto px-4 d-block fw-bold fs-7  rounded'}>
                            {translate('branchMenuPage.setDateTime')}
                        </button>
                        :
                        <button disabled className={'btn mt-3 text-white btn-secondary m-auto px-4 d-block fw-bold fs-7  rounded'}>
                            {translate('branchMenuPage.setDateTime')}
                        </button>
                    }

                </> :
                <Spinner date={'date'}/>}
        </>
    )

}

export default DateAndTime
